import './App.css';

function App() {
  return (
      <div className="App">
        <header className="App-header">
          <h1>Marcus Klein <span className="font-light">(he/him)</span></h1>
          <h3>Software and Dinosaurs</h3>
          <h4>📍 Wellington, NZ</h4>
          <div>
            <a href="https://twitter.com/nzmilky" className="fa fa-twitter" target="_blank" rel="noreferrer">
              <span className="sr-only">Twitter</span>
            </a>
            <a href="https://www.linkedin.com/in/marcusklein-dev/" className="fa fa-linkedin" target="_blank" rel="noreferrer">
              <span className="sr-only">LinkedIn</span>
            </a>
            <a href="https://www.instagram.com/marcus.nz" className="fa fa-instagram" target="_blank" rel="noreferrer">
              <span className="sr-only">Instagram</span>
            </a>
          </div>
        </header>
      </div>
  );
}

export default App;
